import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';

import {AppStateService} from '@core/modules';
import {AppRouterService} from '@core/services';

@Injectable()
export class ChangePasswordGuard  {

  constructor(
    private readonly appRouterService: AppRouterService,
    private readonly appStateService: AppStateService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.needChangePassword();
  }

  private needChangePassword(): Observable<boolean> {
    return this.appStateService.needChangePassword$.pipe(
      map(value => !value),
      tap((value) => {
        if (!value) {
          this.appRouterService.changePassword();
        }
      }),
      take(1),
    );
  }

}
